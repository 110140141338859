import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

function Histo() {

    const [history, setHistory] = useState([]);
    
    useEffect(() => {
        setHistory(JSON.parse(localStorage.getItem('histo')));
    }, [])

    return (
        <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-[80%] w-[100%]  overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <span>Mon historique de recherches</span>
            </div>
            <div className='flex-shrink max-h-[90%]  w-full font-bahn overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
            {history && history.map(h => (
                <div className="w-full bg-white border-b border-bleu">
                    <div className="flex flex-row w-full h-full">
                        {<Link to={"/profil/client/"+h.split(' / ')[0] + "/" + h.split(' / ')[2]} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                            <div> 
                                <ul className="text-xs w-full text-marineLogo">
                                    <li className="w-full rounded-sm">
                                        <span className=" w-full font-bahn">{h.split(' / ')[1]} {h.split(' / ')[2]}</span>
                                    </li>
                                </ul>
                            </div>
                        </Link>}
                    </div>
                </div>
            ))}
            </div>
           
        </div>
    );
}

export default Histo;
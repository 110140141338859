import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import db from '../../Db/db';

function Etapes() {

    const [etapesData, setEtapesData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data = await db.etapes.toArray();
            setEtapesData(data);
        }

        fetchData();
    }, []);

    return (
        <div className='bg-blanc font-bahn border border-marineLogo rounded-lg flex flex-col h-[85%] w-[100%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <span>Mes futures étapes</span>
            </div>
            <div className='flex flex-col items-start justify-start px-4'>
                {etapesData.map((e, index) => (
                    <div key={index} className="w-full bg-white border-b border-bleu mb-2 p-2 rounded-md">
                        <Link to={`/profil/client/${e.codeClient}/${e.codeAgence}`} className="focus:outline-none focus:text-indigo-400 text-left text-marineLogo font-bahn w-full">
                            <div className="flex flex-col">
                                <span className="text-xs font-bold">
                                    Date : <span className="font-normal">{new Date(e.date).toLocaleDateString('fr-FR')}</span>
                                </span>
                                <span className="text-xs font-bold">
                                    Client : <span className="font-normal">{e.nomClient}</span>
                                </span>
                                <span className="text-xs font-bold">
                                    Sujet : <span className="font-normal">{e.sujet}</span>
                                </span>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Etapes;

import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import {Link} from "react-router-dom";
import axios from "axios";


function EnableProspect({ url }) {

    const [prospectData, setProspectData] = useState([]);
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [submitData, setSubmitData] = useState([]);
    const [clients, setClients] = useState([]);
    const [inputVisible, setInputVisible] = useState({});

    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData)
        getProspect(submitFilterData);

    };

    useEffect(() => {
        
        axios.get(url+'/client/all/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        }).then(res => {         
            setClients(res.data);
            
        }).catch(error=>{
            console.log(error);
            
        });
        
    }, [url]);

    useEffect(() => {
        
        getProspect(submitData);
    }, [url]);

    function getProspect(submitData){
        axios.get(url+'client/prospect', {
            params: {
                searchTerm: submitData.searchTerm,
                commercial: submitData.commercial,
                selectedAgence: submitData.selectedAgence
            },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {       
            setProspectData(res.data);
        })
        .catch(error=>{
            console.log(error);
            
        });
    }

    const handleConsoliderClick = (index) => {
        setInputVisible((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleClientSelection = (e, index) => {
        const selectedClient = e.target.value;
        console.log(`Selected client for prospect ${index}:`, selectedClient);
    };
    const handleResetSelection = (index) => {
        setInputVisible((prevState) => ({
            ...prevState,
            [index]: false
        }));
    };
    const submit = (index) => {
        const client = inputVisible[index]?.selectedClient;
        const [codeClient, agenceClient] = client.split(' / ');

        const prospect = prospectData[index];
        const codeProsp = prospect.codeClient;
        const agencProsp = prospect.codeAgence;

        axios.put(url + 'client/disableProspect',
        {
            codeProsp,
            agencProsp,
            codeClient,
            agenceClient,
        },
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        .then((res) => {
            console.log('Prospect désactivé avec succès :', res.data);
        })
        .catch((error) => {
            console.error('Erreur lors de la désactivation du prospect :', error);
        });
    };
    
    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Désactiver prospect" btn={false}  onSubmitFilter={handleSubmit} />
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[80%] h-full font-bahn'>
                        <div className='h-[4%] w-full'>
                            <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[80%] border-collapse ">
                                <thead className='h-[6%] max-h-1.5'>
                                    <tr className='bg-marineLogo text-blanc'>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Commercial</th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Prospect</th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Client</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className='h-[80%] w-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>                
                            <table className="table-fixed h-[96%] border-collapse items-center bg-transparent w-[80%]">
                                <tbody className=' overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[94%] min-h-[50%]'>
                                    {prospectData.map((prospect, index) => (
                                        <tr key={index} className='max-h-[10%] min-h-[5%] border border-marineLogo border-r-0 border-l-0'>
                                            <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">{prospect.name} {prospect.firstName}</td>
                                            <Link to={"/profil/client/" + prospect.codeClient + "/" + prospect.codeAgence}>
                                                <td className="whitespace-nowrap text-xs p-2 text-marineLogo">{prospect.nomClient}</td>
                                            </Link>
                                            <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">
                                                {!inputVisible[index] ? (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleConsoliderClick(index)}
                                                        className="bg-vertLogo w-[30%] flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                                                    >
                                                        Consolider
                                                    </button>
                                                ) : (
                                                    <div className='flex flex-row items-center'>
                                                        <div className=''>
                                                            <input
                                                                type="text"
                                                                list="clientList"
                                                                placeholder="Sélectionnez un client"
                                                                onChange={(e) => handleClientSelection(e, index)}
                                                                className="rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-full"
                                                            />
                                                            <datalist id="clientList">
                                                                {clients.map((client) => (
                                                                    <option
                                                                        key={client.codeClient}
                                                                        value={`${client.nomClient} / ${client.codeAgence}`}
                                                                    >
                                                                        {client.nomClient} ({client.codeAgence})
                                                                    </option>
                                                                ))}
                                                            </datalist>
                                                        </div>
                                                        <svg 
                                                            onClick={() => submit(index)}submit
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            fill="none" 
                                                            viewBox="0 0 24 24" 
                                                            stroke-width="1.5" 
                                                            stroke="#9EBF43" 
                                                            className="w-6 h-6"
                                                        >
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>
                                                        <svg 
                                                            onClick={() => handleResetSelection(index)}
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            fill="none" 
                                                            viewBox="0 0 24 24" 
                                                            stroke-width="1.5"
                                                            stroke="#952A5A" 
                                                            className="w-6 h-6"
                                                        >
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnableProspect;
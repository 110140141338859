import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import db from '../../Db/db';

function Top({ submitData }) {
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [rowCount, setRowCount] = useState(10);
    const [agence, setAgence] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState("tous");
    const [commerciaux, setCommerciaux] = useState([]);
    const [commercial, setCommercial] = useState("tous");

    useEffect(() => {
        async function fetchData() {
            const [ data5, data6] = await Promise.all([
                db.agences.toArray(),
                db.team.toArray()
            ]);
            setAgence(data5);
            setCommerciaux(data6)
        }
        fetchData();
    }, []);

    useEffect(() => {
        handleFilter(submitData);
    }, [selectedAgence, submitData, commercial, rowCount]);

    const aggregateDataByClientAndAgence = (data) => {
        const sumByClientAndAgence = {};

        data.forEach(item => {
            const { codeClient, codeAgence, CA, nom, nomClient, commercial } = item;
            if (!sumByClientAndAgence[codeClient]) {
                sumByClientAndAgence[codeClient] = {};
            }
            if (!sumByClientAndAgence[codeClient][codeAgence]) {
                sumByClientAndAgence[codeClient][codeAgence] = {
                    sumCA: 0,
                    nom,
                    nomClient,
                    commercial
                };
            }
            const caValue = CA !== null ? parseFloat(CA) : 0;
            sumByClientAndAgence[codeClient][codeAgence].sumCA += caValue;
        });

        return Object.entries(sumByClientAndAgence).flatMap(([codeClient, agences]) =>
            Object.entries(agences).map(([codeAgence, data]) => ({
                codeClient,
                codeAgence,
                ...data
            }))
        );
    };

    const handleRowCountChange = (event) => setRowCount(parseInt(event.target.value, 10));

    const handleFilter = (submitData = {}) => {
        
        const searchTerm = (submitData.searchTerm || "").toLowerCase();
        
    
        db.top.toArray().then((data) => {
            let filteredData = [...data];
    
            
            let start = null;
            let end = null;
    
            if (submitData?.startDate && submitData?.endDate) {
                start = new Date(submitData.startDate.getFullYear(), 0, 1);
                end = new Date(submitData.endDate);
            } else {
                start = new Date(new Date().getFullYear(), 0, 1); 
                end = new Date();
            }
            
            start.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 999);
    
            if (searchTerm && searchTerm !== "") {
                const searchParts = searchTerm.split(" / ");
                filteredData = filteredData.filter(client => {
                    return (
                        client.codeClient.toLowerCase() === searchParts[0] &&
                        client.nomClient.toLowerCase().includes(searchParts[1] || "") &&
                        client.codeAgence.toLowerCase() === (searchParts[2] || "")
                    );
                });
            }
    
            if (start && end) {
                filteredData = filteredData.filter(client => {
                    const itemDate = new Date(client.annee, client.mois - 1);
                    return itemDate >= start && itemDate <= end;
                });
            }
    
            if (selectedAgence && selectedAgence !== "tous") {
                filteredData = filteredData.filter(client => client.codeAgence === selectedAgence);
            }

            if (commercial && commercial !== "tous"){
                filteredData = filteredData.filter(client => client.commercial === commercial);
            }

            if (filteredData.length === 0) {
                const [codeClient, nomClient, codeAgence] = searchTerm.split(' / ');
                filteredData.push({
                    codeClient,
                    nomClient,
                    codeAgence,
                    sumCA: 0,
                });
            }
    
            const aggregatedData = aggregateDataByClientAndAgence(filteredData);
    
            const finalData = aggregatedData.sort((a, b) => b.sumCA - a.sumCA).slice(0, rowCount);
            setClientData(finalData);
        });
    };


    return (
        <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-full w-[100%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <span>Mon top </span>
                <select
                    id="rowCount"
                    className="px-2 py-1 border rounded text-xs"
                    value={rowCount}
                    onChange={handleRowCountChange}
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
            </div>
            <div className='flex items-center justify-center'>
                <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[98%] border-collapse ">
                    <thead className='h-[6%] max-h-1.5 text-blanc'>
                        <tr className='bg-marineLogo'>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[30%]">Nom client</th>
                            {userInfo.user.role === 'Administrateur' || userInfo.user.role === 'Responsable' || userInfo.user.idUser === 9 ? ( 
                                <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[20%]">
                                    Commercial : 
                                    <select
                                        value={commercial}
                                        onChange={(e) => setCommercial(e.target.value)}
                                        className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                                    >
                                        <option value="tous">Tous</option> 
                                        {commerciaux.map((commercial) => ( 
                                            <option key={commercial.idUser} value={commercial.idUser}>
                                                {commercial.name} {commercial.firstName}
                                            </option>
                                        ))}
                                    </select>
                                </th>
                            ) : null}
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[20%]">
                            Agence :
                            <select
                                value={selectedAgence}
                                onChange={(e) => setSelectedAgence(e.target.value)}
                                className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                            >
                                <option value="tous">Toutes</option>
                                {agence.map((agenceItem) => (
                                    <option key={agenceItem.code} value={agenceItem.code}>
                                        {agenceItem.nom}
                                    </option>
                                ))}
                            </select>
                            </th>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center w-[10%]">CA N</th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto max-h-[94%] min-h-[50%]'>
                        {clientData.slice(0, rowCount).map((member, index) => (
                            <tr key={index} className='max-h-[10%] min-h-[5%]'>
                                <Link to={`/profil/client/${member.codeClient}/${member.codeAgence}`} className="focus:outline-none text-left text-marineLogo font-bold flex justify-start items-center w-full h-full p-[1%]">
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">{member.nomClient} ({member.codeClient})</td>
                                </Link>
                                {userInfo.user.role === 'Administrateur' || userInfo.user.role === 'Responsable' || userInfo.user.idUser === 9 ? ( 
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                        {commerciaux
                                        .filter((commercial) => commercial.idUser == member.commercial)
                                        .map((commercial) => (
                                            <span key={commercial.idUser}>
                                                {commercial.name} {commercial.firstName}
                                            </span>
                                        ))}
                                    </td>
                                ) : null}
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">{member.codeAgence}</td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap pr-[5%] text-right">
                                {member.sumCA !== null && member.sumCA !== undefined && !isNaN(parseFloat(member.sumCA))
                                    ? parseFloat(member.sumCA).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                                    : "0,00"} €
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Top;

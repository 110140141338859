import React, { useEffect, useState } from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import axios from "axios";
import {Link} from "react-router-dom";
import db from '../../Db/db';

export default function Equipes({url}) {
    
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    console.log(userInfo)
    const [searchTerm, setSearchTerm] = useState('');
    const [teamData, setTeamData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data1 = await db.team.toArray();
            setTeamData(data1);
        }

        fetchData();
    }, []);

   

    const handleSearch = event => {
        setSearchTerm(event.target.value);
      };
    
      const filteredMembers = teamData.filter(member =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.firstName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      function disabled(login) {
        axios.put(url+"users/disabled/",{
            login: login
        }, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
      }

      

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Mon Equipe" btn={false} />
                </div>


                <div className='flex flex-wrap mt-4 justify-center'>
                    <table className="w-[50%] h-full table-auto">
                        <thead>
                            <tr className="border bg-marineLogo text-blanc">
                            <th className="border px-4 ">Nom Prénom</th>
                            <th className="border px-4 ">Fonction</th>
                            <th className="border px-4 ">Agence</th>
                            {userInfo.user.role === 'Administrateur' || userInfo.user.idUser === 9 ? (<th className="border px-4 ">Désactiver</th>) : null}
                            </tr>
                        </thead>
                        <tbody>
                        {filteredMembers.map((member, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                            <td className=" px-4  text-bleu"><Link to={"/profil/"+member.idUser}>{member.name} {member.firstName}</Link></td>
                            <td className=" px-4 ">{member.title}</td>
                            <td className=" px-4 ">{member.agency}</td>
                            {/*userInfo.user.role === 'Administrateur' || userInfo.user.idUser === 9 ? (<td className=" px-4 ">
                                <Link to={"/ca/"+member.idUser} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-bleu font-bold bg-white  rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">SON CA</Link>
                            </td>) : null*/}
                            {userInfo.user.role === 'Administrateur' || userInfo.user.idUser === 9 ? (<td onClick={() => disabled(member.login)} className=" px-4 "> <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#952a5a" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> </td> ) : null}
                        </tr>
                        ))}
                        
                        </tbody>
                    </table>
                    </div>
            </div>
        </div>
    );
}
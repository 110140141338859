import React, { useState, useEffect } from 'react';
import axios from "axios";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import db from '../../Db/db';



function Affect({ url }) {
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [affect, setAffect] = useState([])
    
    const [showSelect, setShowSelect] = useState(false);
    const [addComm, setAddComm] = useState(false);
    const [commerciaux, setCommerciaux] = useState([]);
    const [agence, setAgence] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState("aucun");
    const [associerDissocier, setAssocierDissocier] = useState("aucun");
    const [currentSearch, setCurrentSearch] = useState("");



    useEffect(() => {
        async function fetchData() {
            const data1 = await db.clients.toArray();
            setAffect(data1.filter(d => d.typeClient === 'client-effectif'));
            const data2 = await db.team.toArray();
            setCommerciaux(data2)
            const data3 = await db.agences.toArray();
            setAgence(data3);
        }
        fetchData();
    }, []);


    const handleDisableClick = async (codeClient, codeAgence) => {
        try {
            handleAssignCommercial(codeClient, codeAgence, null)
            await db.clients.update({ codeClient, codeAgence }, { commercial: null });
            handleFilter({ searchTerm: currentSearch });
            console.log("Mise à jour locale réussie !");
        } catch (error) {
        console.error("Erreur lors de l'association :", error);
        }
    }

    const handleAssignCommercial = async (codeClient, codeAgence, commercialId) => {
        console.log("Affectation :", codeClient, codeAgence, commercialId);
    
        try {
            await axios.put(url + 'client/affect', {
                clients: [`${codeClient} - ${codeAgence}`],
                codeCommercial: commercialId,
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    'Authorization': 'Bearer ' + userInfo.token
                }
            });
    
            await db.clients.update({ codeClient, codeAgence }, { commercial: commercialId });
            handleFilter({ searchTerm: currentSearch });
            setAddComm(!addComm)
            console.log("Mise à jour locale réussie !");
        } catch (error) {
            console.error("Erreur lors de l'association :", error);
        }
    
        setShowSelect((prev) => ({
            ...prev,
            [`${codeClient}-${codeAgence}`]: false,
        }));
    };

    useEffect(() => {
        handleFilter();
    }, [selectedAgence, associerDissocier, addComm]);

    const handleFilter = (submitFilterData = {}) => {
        const searchTerm = (submitFilterData.searchTerm || currentSearch).toLowerCase();
        setCurrentSearch(searchTerm);
    
        db.clients.toArray().then((data) => {
            let filteredData = []
    
            if (searchTerm) {
                const searchParts = searchTerm.split(" / ");
                filteredData = data.filter((client) => {
                    return (
                        client.codeClient.toLowerCase() === searchParts[0] &&
                        client.nomClient.toLowerCase() === (searchParts[1] || "") &&
                        client.codeAgence.toLowerCase() === (searchParts[2] || "")
                    );
                });
            }else{
                filteredData = data.filter((d) => d.typeClient === 'client-effectif');
            }
    
            if (selectedAgence === "aucun") {
                filteredData = filteredData.filter((client) => !client.codeAgence || client.codeAgence === "");
            } else if (selectedAgence) {
                filteredData = filteredData.filter((client) => client.codeAgence === selectedAgence);
            }
    
            if (associerDissocier === "aucun") {
                filteredData = filteredData.filter((client) => !client.commercial || client.commercial === "");
            } else if (associerDissocier) {
                filteredData = filteredData.filter((client) => client.commercial === associerDissocier);
            }
    
            setAffect(filteredData);
        });
    };


   
    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Affectations Clients" btn={false} onSubmitFilter={handleFilter} />
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[80%] h-full font-bahn '>
                        <div className='flex flex-col h-full w-full'>
                            <div className='h-[4%] w-full'>
                                <table className="table-fixed h-[100%] border-collapse items-center bg-transparent w-full ">
                                    <thead className='h-full max-h-1.5'>
                                        <tr className='bg-marineLogo text-blanc'>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Client</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                Agence :
                                                <select
                                                    value={selectedAgence}
                                                    onChange={(e) => setSelectedAgence(e.target.value)}
                                                    className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                                                >
                                                    <option value="aucun">Aucune</option>
                                                    <option value="">Toutes</option>
                                                    {agence.map((agenceItem) => (
                                                        <option key={agenceItem.code} value={agenceItem.code}>
                                                            {agenceItem.nom}
                                                        </option>
                                                    ))}
                                                </select>
                                            </th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                            Commercial :
                                            <select
                                                value={associerDissocier}
                                                onChange={(e) => setAssocierDissocier(e.target.value)}
                                                className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                                            >
                                                <option value="aucun">Aucun</option>
                                                <option value="">Tous</option> 
                                                {commerciaux.map((commercial) => ( 
                                                        <option key={commercial.idUser} value={commercial.idUser}>
                                                            {commercial.name} {commercial.firstName}
                                                        </option>
                                                    ))}
                                            </select>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className='w-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>                
                                <table className="table-fixed border-collapse items-center bg-transparent w-full">
                                    <tbody className='h-auto overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                        {affect.map((client, index) => (
                                            <tr key={index} className='max-h-[10%] min-h-[5%] border border-b-1 border-l-0 border-r-0 border-marineLogo'>
                                                <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-marineLogo">{client.nomClient}<br />  <span className='text-noir'>({client.codeClient})</span></td>
                                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">{client.codeAgence}</td>
                                                <td>
                                                {client.commercial && client.commercial !== '' ? (
                                                    <div className='flex'>
                                                    
                                                        {commerciaux.filter((commercial) => commercial.idUser == client.commercial)
                                                        .map((commercial) => (
                                                            <span key={commercial.id}>
                                                            {commercial.firstName} {commercial.name}
                                                            </span>
                                                        ))}
                                                        <svg
                                                            onClick={() => {
                                                                handleDisableClick(client.codeClient, client.codeAgence);
                                                                setShowSelect((prev) => ({
                                                                    ...prev,
                                                                    [`${client.codeClient}-${client.codeAgence}`]: true,
                                                                }));
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="#952A5A"
                                                            className="w-6 h-6"
                                                        >
                                                            <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                        </svg>
                                                    
                                                    </div>
                                                ) : (
                                                    <div className='flex'>
                                                        <svg 
                                                            onClick={() => setShowSelect((prev) => ({ ...prev, [`${client.codeClient}-${client.codeAgence}`]: true }))}
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            fill="none" 
                                                            viewBox="0 0 24 24" 
                                                            stroke-width="1.5" 
                                                            stroke="#9EBF43" 
                                                            className="w-6 h-6"
                                                        >
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>

                                                        
                                                    </div>
                                                )}
                                                {showSelect[`${client.codeClient}-${client.codeAgence}`] && (
                                                    <div className='flex'>
                                                        <select
                                                            onChange={(e) => handleAssignCommercial(client.codeClient, client.codeAgence, e.target.value)}
                                                            className="select-commercial"
                                                        >
                                                            <option value="">Sélectionnez un commercial</option>
                                                            {commerciaux.map((commercial) => (
                                                                <option key={commercial.idUser} value={commercial.idUser}>
                                                                    {commercial.firstName} {commercial.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <svg 
                                                            onClick={() => {
                                                                handleDisableClick(client.codeClient, client.codeAgence);
                                                                setShowSelect((prev) => ({
                                                                    ...prev,
                                                                    [`${client.codeClient}-${client.codeAgence}`]: false,
                                                                }));
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            fill="none" 
                                                            viewBox="0 0 24 24" 
                                                            stroke-width="1.5"
                                                            stroke="#952A5A" 
                                                            className="w-6 h-6"
                                                        >
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>
                                                    </div>

                                                )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Affect;
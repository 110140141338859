import React, { useState, useMemo, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import db from '../../Db/db';


export default function Filtre({ onSubmitFilter }) {
    const [searchTerm, setSearchTerm] = useState('');
    
    const [inputValue, setInputValue] = useState('');
    const [clientData, setClientData] = useState([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [startDate, setStartDate] = useState(new Date(currentYear , 0, 2));
    const [endDate, setEndDate] = useState(currentDate);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const fetchClients = async () => {
            const data = await db.clients.toArray();
            setClientData(data);
        };
        fetchClients();
    }, []);


    const saveSearchTerm = (searchTerm) => {
        const searchHistory = JSON.parse(localStorage.getItem('histo')) || [];
        const termIndex = searchHistory.findIndex(term => term === searchTerm);

        if (termIndex === -1) {
            searchHistory.push(searchTerm);
            localStorage.setItem('histo', JSON.stringify(searchHistory));
        }
    };

    
    const handleStartDateChange = (e) => {
        const newDate = new Date(e.target.value); 
        setStartDate(newDate);
    };
    
    const handleEndDateChange = (e) => {
        const newDate = new Date(e.target.value);
        setEndDate(newDate);
    };
    const handleDateFocus = (e) => {
        e.target.value = "";
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        saveSearchTerm(searchTerm);
        const filterData = {
            searchTerm,
            startDate,
            endDate,
        };
        onSubmitFilter(filterData)
        
        if(isSidebarOpen){
            setIsSidebarOpen(!isSidebarOpen);
        }
        
    };


    const filteredOptions = useMemo(() => {
        if (!clientData || clientData.length === 0) {
            return [];
        }
        
        return clientData
            .filter(client =>
                client.nomClient?.toLowerCase().includes(inputValue.toLowerCase())
            )
            .slice(0, 20)
            .map(client => ({
                label: `${client.codeClient} / ${client.nomClient} / ${client.codeAgence}`,
                value: `${client.codeClient} / ${client.nomClient} / ${client.codeAgence}`,
            }));
            
    }, [inputValue, clientData]);
    

    const loadOptions = async (inputValue) => {
        const data = await db.clients
            .filter(client => client.nomClient.toLowerCase().includes(inputValue.toLowerCase()))
            .limit(20)
            .toArray();
    
        return data.map(client => ({
            label: `${client.nomClient} ${client.codeAgence}`,
            value: `${client.codeClient} / ${client.nomClient} / ${client.codeAgence}`,
        }));
    };

    return (
        <div className='absolute top-[5%]  md:top-[6%] left-[60%] pr-8'>
            <form className="flex items-center z-10 w-[500px]" onSubmit={handleSubmit}>
                <div className='flex flex-column w-full'>
                    <div className='relative w-full '>
                        
                        <div className='flex w-full'>
                            <label htmlFor='simple-search' className='sr-only'>
                                Search
                            </label>
                            <div>
                               {/*} <AsyncSelect
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    defaultOptions={[]}
                                    onChange={(selected) => setSearchTerm(selected?.value || '')}
                                    isClearable
                                    placeholder="Rechercher un client..."
                                    className='w-full'
                                />*/}
                                <Select 
                                    options={filteredOptions}
                                    onInputChange={(input) => setInputValue(input)} // Gère l'entrée de l'utilisateur
                                    onChange={(selected) => setSearchTerm(selected?.value || '')}
                                    isClearable
                                    placeholder="Rechercher un client..."
                                    className='w-full'
                                />
                                <div className="flex justify-between mb-4 w-full mt-4">
                                    <div className="flex items-center">
                                        <label htmlFor="date-du" className="text-sm text-marineLogo mr-4">DATE DU : </label>
                                        <input
                                            type="date"
                                            id="date-du"
                                            className="border border-marineLogo text-sm rounded p-1"
                                            value={startDate.toISOString().split('T')[0]}
                                            onFocus={handleDateFocus}
                                            onChange={handleStartDateChange}
                                        />
                                    </div>
                                    <div className="flex items-center ml-4">
                                        <label htmlFor="date-au" className="text-sm text-marineLogo mr-4"> AU : </label>
                                        <input
                                            type="date"
                                            id="date-au"
                                            className="border border-marineLogo text-sm rounded p-1"
                                            value={endDate.toISOString().split('T')[0]}
                                            onFocus={handleDateFocus}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-vertLogo p-2 ml-2 text-sm font-medium rounded  hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                            >
                                <svg className="w-5 h-5 group-hover:stroke-vertLogo" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

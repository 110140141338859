import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import VisitePro from '../Containers/VisitePro';
import db from '../../Db/db';
import 'react-datepicker/dist/react-datepicker.css';

export default function Visites() {
    const userInfo = JSON.parse(localStorage.getItem("token") || "{}");

    const navigate = useNavigate();

    const [visitesData, setVisitesData] = useState([]);
    const [prevoirVisites, setPrevoirVisites] = useState([]);
    const [activite, setActivite] = useState([]);
    const [actClient, setActClient] = useState([]);
    const [departClient, setDepartClient] = useState([]);
    const [commerciaux, setCommerciaux] = useState([]);
    const [agence, setAgence] = useState([]);

    const [submitData, setSubmitData] = useState({});
    const [selectedAgence, setSelectedAgence] = useState("toutes");
    const [selectedCommercial, setSelectedCommercial] = useState("tous");

    useEffect(() => {
        async function fetchData() {
            const [visites, prevoir, act, actClientData, departData, agences, team] = await Promise.all([
                db.visites.toArray(),
                db.visitePrevoir.toArray(),
                db.act.toArray(),
                db.activite.toArray(),
                db.depart.toArray(),
                db.agences.toArray(),
                db.team.toArray(),
            ]);

            setVisitesData(visites.sort((a, b) => new Date(b.dateVisite) - new Date(a.dateVisite)));
            setPrevoirVisites(prevoir);
            setActivite(act);
            setActClient(actClientData);
            setDepartClient(departData);
            setAgence(agences);
            setCommerciaux(team);
        }

        fetchData();
    }, []);

    const filteredVisites = useMemo(() => {
        let filtered = [...visitesData];

        if (submitData.searchTerm) {
            const [codeClient, , codeAgence] = submitData.searchTerm.split(" / ").map(term => term.trim());
            if (codeClient) {
                filtered = filtered.filter(v => v.codeClient === codeClient && (!codeAgence || v.codeAgence === codeAgence));
            }
        }

        if (submitData.startDate && submitData.endDate) {
            const startDate = new Date(submitData.startDate);
            const endDate = new Date(submitData.endDate);
            filtered = filtered.filter(v => {
                const visitDate = new Date(v.dateVisite);
                return visitDate >= startDate && visitDate <= endDate;
            });
        }

        if (selectedAgence !== "toutes") {
            filtered = filtered.filter(v => v.codeAgence === selectedAgence);
        }

        if (selectedCommercial !== "tous") {
            filtered = filtered.filter(v => v.idUser === parseInt(selectedCommercial));
        }

        return filtered;
    }, [visitesData, submitData, selectedAgence, selectedCommercial]);

    const getActivityLabels = (codeClient, codeAgence) => {
        if (!activite || !actClient) return { displayLabel: "", fullLabel: [] };
    
        const actEntry = activite.find(act => act.codeClient === codeClient && act.codeAgence === codeAgence);
    
        if (!actEntry || !actEntry.idActMF) return { displayLabel: "", fullLabel: [] };
    
        const idsActivite = actEntry.idActMF.split(', ').map(id => parseInt(id.trim()));
    
        const activitesLibelle = idsActivite.map(id => {
            const activite = actClient.find(act => act.value === id);
            return activite ? activite.label : "";
        });
    
        if (activitesLibelle.length > 1) {
            return {
                displayLabel: `${activitesLibelle[0]}, ...`,
                fullLabel: activitesLibelle
            };
        }
    
        return {
            displayLabel: activitesLibelle[0] || "",
            fullLabel: activitesLibelle[0] ? [activitesLibelle[0]] : []
        };
    };

    const getDepartLabels = (codeClient, codeAgence) => {
        if (!departClient) return { displayDepart: "", fullDepart: [] };

        const departEntries = departClient.filter(dep => dep.codeClient === codeClient && dep.codeAgence === codeAgence);

        if (!departEntries || departEntries.length === 0) return { displayDepart: "", fullDepart: [] };

        const departLibelle = departEntries.map(dep => `${dep.ville}, ${dep.pays} (${dep.cp})`);

        if (departLibelle.length > 1) {
            return {
                displayDepart: `${departLibelle[0]}, ...`,
                fullDepart: departLibelle
            };
        }

        return {
            displayDepart: departLibelle[0],
            fullDepart: departLibelle
        };
    };

    return (
        
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => navigate(-1)} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                <div className="h-[15%] w-full">
                    
                    <Title title="Mes Visites" btn onSubmitFilter={setSubmitData} />
                </div>
                <div className="flex h-[85%] w-full px-4 pt-8">
                    {/* Tableau des visites passées */}
                    <div className="w-[70%] h-[95%]">
                        <div className="font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-full w-full overflow-auto  mt-4">
                            <div className="border-b border-marineLogo p-2  text-marineLogo">Mes visites passées</div>
                            <table className="table-fixed w-full bg-transparent">
                            <thead className='h-[6%] max-h-1.5 text-blanc'>
                                <tr className='bg-marineLogo'>
                                    <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Nom client</th>
                                    <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                                        <div className='Flex flex-col'>
                                        <div>Agence</div>
                                        <div>
                                            <select
                                                value={selectedAgence}
                                                onChange={(e) => setSelectedAgence(e.target.value)}
                                                className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                                            >
                                                <option value="toutes">Toutes</option>
                                                {agence.map((agenceItem) => (
                                                    <option key={agenceItem.code} value={agenceItem.code}>
                                                        {agenceItem.nom}
                                                    </option>
                                                ))}
                                            </select>
                                                </div>
                                                </div>
                                            </th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                                                Activité
                                            </th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Secteur</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Points de départ</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                                            <div className='Flex flex-col'>
                                            <div>Commercial</div>
                                                {userInfo.user.role === "Administrateur" || userInfo.user.role === "Responsable" ? (
                                                    <div><select
                                                        value={selectedCommercial}
                                                        onChange={(e) => setSelectedCommercial(e.target.value)}
                                                        className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                                                    >
                                                        <option value="tous">Tous</option> 
                                                        {commerciaux.map((commercial) => ( 
                                                            <option key={commercial.idUser} value={commercial.idUser}>
                                                                {commercial.name} {commercial.firstName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    </div>
                                                ): null}
                                            </div>    
                                            </th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Date du RDV</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"></th>
                                        </tr>
                                    </thead>
                                <tbody className='overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                        {filteredVisites.map(visit => {
                                            const { displayLabel, fullLabel } = getActivityLabels(visit.codeClient, visit.codeAgence);
                                            const { displayDepart, fullDepart } = getDepartLabels(visit.codeClient, visit.codeAgence);
                                            return (
                                                <tr key={visit.id} className='bg-white'>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-left">
                                                        <Link to={"/profil/client/" + visit.codeClient + "/" + visit.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                                                            <span>{visit.nomClient}</span>
                                                        </Link>
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {visit.codeAgence}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center relative group">
                                                        <span className="cursor-pointer">{displayLabel}</span>
                                                        {fullLabel.length > 1 && (
                                                            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-marineLogo text-left text-blanc z-10 text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                <ul className="list-disc list-inside">
                                                                    {fullLabel.map((label, index) => (
                                                                        <li key={index}>{label}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {visit.activite}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center relative group">
                                                        <span className="cursor-pointer">{displayDepart}</span>
                                                        {fullDepart.length > 1 && (
                                                            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-marineLogo text-left text-blanc z-10 text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                <ul className="list-disc list-inside">
                                                                    {fullDepart.map((depart, index) => (
                                                                        <li key={index}>{depart}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {visit.firstname} {visit.name}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {new Date(visit.dateVisite).toISOString().slice(0, 10).replace("T", " ")}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        <Link to={"/newVisite/" + visit.id} className="inline-flex items-center px-4 py-2 text-sm font-medium text-blanc font-bold bg-vertLogo rounded-lg hover:bg-blanc hover:text-vertLogo hover:border hover:border-vertLogo">Synthèse</Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                
                            </table>
                        </div>
                    </div>

                    {/* Visites à prévoir */}
                    <div className="w-[30%] h-full flex flex-col p-8">
                        <div className="font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-[50%] mb-4">
                            <div className="flex justify-center border-b border-marineLogo p-2 text-marineLogo">Mes visites à prévoir</div>
                            <div className="overflow-auto">
                                {prevoirVisites.map(prevoir => (
                                    <Link
                                        to={`/profil/client/${prevoir.codeClient}/${prevoir.codeAgence}`}
                                        key={prevoir.codeClient}
                                        className="block bg-white border-b border-bleu p-2"
                                    >
                                        <div className="text-xs text-marineLogo">
                                            {prevoir.nomClient} ({prevoir.codeClient})
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <VisitePro />
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useRef, useEffect, useState, useMemo } from 'react';
import useOnlineStatus from '../Containers/useOnlineStatus';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import db from '../../Db/db';
import axios from "axios";

export default function Clients({ url }) {
  const isOnline = useOnlineStatus();
  const json = localStorage.getItem("token")
  const userInfo = JSON.parse(json);
  const [clients, setClients] = useState([]);
  const [visites, setVisites] = useState([]);
  const [etapes, setEtapes] = useState([]);
  const [formData, setFormData] = useState({
    adressEnt: '', adresse2: '', villeEnt: '', cpEnt: '', numEnt: '', emailEnt: '', ca: '',
    activite: '', appel1: null, appel2: null, appel3: null, appel4: null,
    commentaire: '', nextVisitDate: '',
    nomClient: '', nom: '', prenom: '', fonction: '', idCom: userInfo.user.idUser, type: 'prospect', site: userInfo.user.agency
  });
  const [visitDate, setvisitDate] = useState(new Date().toISOString().split('T')[0]);
  const [etap, setEtap] = useState({ text: '', date: '' });
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [commentaireTemp, setCommentaireTemp] = useState('');
  
  const [contact, setContact] = useState([]);
  const [contactClient, setContactClient] = useState([]);
  //const [addContact, setAddContact] = useState([]);

  
  const [commerciaux, setCommerciaux] = useState([]);
  const [agence, setAgence] = useState([]);
  const [selectedAgence, setSelectedAgence] = useState("toutes");
  const [selectedCommercial, setSelectedCommercial] = useState("tous");

  const [submitData, setSubmitData] = useState({
    searchTerm: '',
    selectedAgence: '',
    selectedOptions: [],
    startDate: null,
    endDate: null,
    commercial: ''
  });

  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});

  async function fetchData() {
    const data1 = await db.clients.toArray();
    setClients(data1);
    const data2 = await db.visites.toArray();
    
    setVisites(data2.filter(v => v.typeVisite === "appel" && v.nomClient));
    
    const data8 = await db.contacts.toArray();
    setContact(data8);
    const data3 = await db.etapes.toArray();
    setEtapes(data3);
    
    const data4 = await db.agences.toArray();
    setAgence(data4);
    
    const data5 = await db.team.toArray();
    setCommerciaux(data5);
  }

  useEffect(() => {
    fetchData();
    console.log(visites)
    console.log(contact)
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "visitDate") {
      setvisitDate(value);
    } else if (name === "text" || name === "date") {
      setEtap((prevEtap) => ({
        ...prevEtap,
        [name]: value,
      }));
    } else if (name === 'nomClient' && value.includes(' / ')) {
      const [codeClient, nomClient, codeAgence] = value.split(' / ');
      const prospect = clients.find(cl => cl.codeClient === codeClient && cl.codeAgence === codeAgence);
      setContactClient(contact.filter(co => co.codeClient === codeClient && co.codeAgence === codeAgence));
      if (prospect) {
        setFormData({
          ...formData,
          adressEnt: prospect.adressClient,
          adresse2: prospect.adresse2,
          villeEnt: prospect.villeClient,
          cpEnt: prospect.cpClient,
          numEnt: prospect.telClient,
          emailEnt: prospect.emailClient,
          ca: prospect.caAnnuel,
          activite: prospect.activite,
          codeClient: codeClient,
          nomClient: nomClient,
          site: codeAgence
        });
      }
    } else if (name === 'nom' && value.includes(' / ')){
      const [nom, prenom, fonction] = value.split(' / ');
      
      
      console.log(contactClient)
      setFormData({
        ...formData,
        nom: nom,
        prenom: prenom,
        fonction: fonction
      });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const openCommentPopup = () => {
    setCommentaireTemp(formData.commentaire);
    setShowCommentPopup(true);
  };

  const closeCommentPopup = () => {
    setShowCommentPopup(false);
  };

  const saveCommentaire = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      commentaire: commentaireTemp,
    }));
    setShowCommentPopup(false);
  };

  const startEditing = (visite) => {
    setEditingRow(visite.id);
    setEditedData(visite);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const truncateText = (text, maxLength = 60) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }

  const saveChanges = async () => {
    try {
      await db.visites.put(editedData); 
      setVisites((prevVisites) =>
        prevVisites.map((v) => (v.id === editedData.id ? editedData : v))
      );

      // Préparer les étapes à envoyer : tableau vide si aucune étape n'est présente
      const etapesToSend = etapes.filter(etape => etape.idVisite === editedData.id).length > 0 ? etapes.filter(etape => etape.idVisite === editedData.id) : [];

    
      const dataToSend = {
        formData: {
          idUser: editedData.idUser,
          name: editedData.name,
          firstname: editedData.firstname,
          codeClient: editedData.codeClient,
          nomClient: editedData.nomClient,
          activite: editedData.secteur,
          site: editedData.codeAgence,
          type: 'appel',
          adressEnt: editedData.adressClient,
          cpEnt: editedData.cpClient,
          villeEnt: editedData.villeClient,
          paysEnt: editedData.paysClient,
          numEnt: editedData.telClient,
          numEnt: editedData.emailClient,
          budgetTL: editedData.budgetTL,
          caAnnuel: editedData.caAnnuel,
          nom: editedData.nom,
          prenom: editedData.prenom,
          email: editedData.mailContact,
          num: editedData.telContact,
          fonction: editedData.fonction,
          topics: editedData.commentaire,
          appel1: editedData.appel1,
          appel2: editedData.appel2,
          appel3: editedData.appel3,
          appel4: editedData.appel4,
          nextVisitDate: editedData.nextVisitDate ? editedData.nextVisitDate : null,
          visitDate: editedData.dateVisite ? editedData.dateVisite : null,
          idCom: userInfo.user.idUser
        },
        etapes: etapesToSend, // Envoyer un tableau vide si aucune étape n'est présente
        materials: [],
        deps: [],
        url: null,
        docu: null,
        visitDate: editedData.dateVisite ? editedData.dateVisite : null,
      };

      const response = await axios.post(
        'https://api.hautier-transports.fr/crm/visit/createUpd',
        dataToSend,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": 'multipart/form-data',
            'Authorization': 'Bearer ' + userInfo.token
          }
        }
      );

      console.log('Réponse du serveur:', response.data);
      alert('La visite a bien été mise à jour sur le serveur.');
      setEditingRow(null);

    } catch (error) {
      console.error("Erreur lors de la mise à jour:", error);
      alert("Erreur lors de la mise à jour de la visite.");
    }
  };

  const cancelEditing = () => {
    setEditingRow(null);
    setEditedData({});
  };

  const handleFilter = (submitFilterData) => {
    setSubmitData(submitFilterData);
  };

  const handleSubmit = async () => {
    const cleanedFormData = {
      ...formData,
      appel1: formData.appel1 && formData.appel1 !== '' ? formData.appel1 : null,
      appel2: formData.appel2 && formData.appel2 !== '' ? formData.appel2 : null,
      appel3: formData.appel3 && formData.appel3 !== '' ? formData.appel3 : null,
      appel4: formData.appel4 && formData.appel4 !== '' ? formData.appel4 : null,
      visitDate: visitDate && visitDate !== '' ? visitDate : null,
      nextVisitDate: formData.nextVisitDate && formData.nextVisitDate !== '' ? formData.nextVisitDate : null,
      type: 'appel'
    };


    // Vérifier si les étapes sont vides
    const etapesToSend = etap.text !== '' || etap.date !== '' ? [etap] : [];

    try {
      if (isOnline) {
        const response = await axios.post(
          'https://api.hautier-transports.fr/crm/visit/createUpd',
          {
            formData: cleanedFormData,
            etapes: etapesToSend, // Envoyer un tableau vide si aucune étape n'est présente
            materials: [],
            deps: [],
            url: null,
            docu: null,
            visitDate: cleanedFormData.visitDate,
            addContact: [{
              nom: formData.nom,
              prenom: formData.prenom,
              fonction: formData.fonction,
              idContact: formData.idContact,
          }],



          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": 'multipart/form-data',
              'Authorization': 'Bearer ' + userInfo.token
            }
          }
        );
        console.log('Réponse du serveur:', response.data);
        alert('La visite a bien été enregistrée');

        localStorage.removeItem('New_visit');
      } else {
        const offlineData = JSON.parse(localStorage.getItem('New_visit')) || [];
        offlineData.push({
          formData: cleanedFormData,
          etapes: etapesToSend,
          materials: [],
          deps: [],
          url: null,
          docu: null,
          visitDate: cleanedFormData.visitDate
        });
        localStorage.setItem('New_visit', JSON.stringify(offlineData));
        alert('Vous êtes hors ligne. Les données ont été sauvegardées localement.');
      }

      const maxId = await db.visites.orderBy('id').last();
      let newId = maxId ? maxId.id + 1 : 1;

      await db.visites.put({
        id: newId,
        name: userInfo.user.name,
        firstname: userInfo.user.firstName,
        idUser: userInfo.user.idUser,
        dateVisite: cleanedFormData.visitDate,
        action: formData.topics,
        urlDoc: '',
        nextVisitDate: cleanedFormData.nextVisitDate,
        codeClient: formData.codeClient,
        nomClient: formData.nomClient,
        secteur: formData.secteur,
        codeAgence: formData.site,
        typeClient: 'appel',
        adressClient: formData.adressEnt,
        cpClient: formData.cpEnt,
        villeClient: formData.villeEnt,
        paysClient: formData.paysEnt,
        telClient: formData.numEnt,
        emailClient: formData.emailEnt,
        budgetTL: formData.budget,
        caAnnuel: formData.ca,
        nom: formData.nom,
        prenom: formData.prenom,
        mailContact: formData.email,
        telContact: formData.num,
        fonction: formData.fonction,
        commentaire: formData.commentaire,
        appel1: cleanedFormData.appel1,
        appel2: cleanedFormData.appel2,
        appel3: cleanedFormData.appel3,
        appel4: cleanedFormData.appel4
      });

      await db.clients.put({
        codeClient: formData.codeClient,
        codeAgence: formData.site,
        nomClient: formData.nomClient,
        adressClient: formData.adressEnt,
        adresse2: formData.adresse2,
        cpClient: formData.cpEnt,
        villeClient: formData.villeEnt,
        paysClient: formData.paysEnt,
        telClient: formData.numEnt,
        emailClient: formData.emailEnt,
        budgetTL: formData.budget,
        caAnnuel: formData.ca,
        activite: formData.activite,
        typeClient: 'prospect',
        commercial: userInfo.user.idUser,
        enfant: null
      });

      const maxIdC = await db.contacts.orderBy('id').last();
      const newIdC = maxIdC ? maxIdC.id + 1 : 1;
      await db.contacts.put({
        id: formData.idContact || newIdC,
        codeClient: formData.codeClient,
        codeAgence: formData.site,
        nom: formData.nom,
        prenom: formData.prenom,
        mailContact: formData.email,
        telContact: formData.num,
        tel2Contact: null,
        fonction: formData.fonction,
        dateVisite: null,
        updateDate: null,
        isActive: 1
      });

      await fetchData();
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      alert('Erreur lors de la soumission du formulaire');
    }
  };

  const scrollContainer1Ref = useRef(null);
  const scrollContainer2Ref = useRef(null);

  const handleScroll1 = () => {
    if (scrollContainer2Ref.current) {
      scrollContainer2Ref.current.scrollTop = scrollContainer1Ref.current.scrollTop;
    }
  };

  const handleScroll2 = () => {
    if (scrollContainer1Ref.current) {
      scrollContainer1Ref.current.scrollTop = scrollContainer2Ref.current.scrollTop;
    }
  };

  useEffect(() => {
    const scrollContainer1 = scrollContainer1Ref.current;
    const scrollContainer2 = scrollContainer2Ref.current;

    if (scrollContainer1 && scrollContainer2) {
      scrollContainer1.addEventListener('scroll', handleScroll1);
      scrollContainer2.addEventListener('scroll', handleScroll2);

      return () => {
        scrollContainer1.removeEventListener('scroll', handleScroll1);
        scrollContainer2.removeEventListener('scroll', handleScroll2);
      };
    }
  }, []);

  const headerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainer2Ref.current;
    const header = headerRef.current;

    if (scrollContainer) {
      const handleScroll = () => {
        if (header) {
          header.scrollLeft = scrollContainer.scrollLeft;
        }
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const filteredVisites = useMemo(() => {
    let filtered = [...visites];

    if (submitData.searchTerm) {
        const [codeClient, , codeAgence] = submitData.searchTerm.split(" / ").map(term => term.trim());
        if (codeClient) {
            filtered = filtered.filter(v => v.codeClient === codeClient && (!codeAgence || v.codeAgence === codeAgence));
        }
    }

    if (submitData.startDate && submitData.endDate) {
        const startDate = new Date(submitData.startDate);
        const endDate = new Date(submitData.endDate);
        filtered = filtered.filter(v => {
            const visitDate = new Date(v.dateVisite);
            const result = visitDate >= startDate && visitDate <= endDate;
            return result;
        });
    }

    if (selectedAgence !== "toutes") {
        filtered = filtered.filter(v => v.codeAgence === selectedAgence);
    }

    if (selectedCommercial !== "tous") {
        filtered = filtered.filter(v => v.idUser === parseInt(selectedCommercial));
    }

    filtered.sort((a, b) => new Date(b.dateVisite) - new Date(a.dateVisite));

    return filtered;
}, [visites, submitData, selectedAgence, selectedCommercial]);




  const getExcelData = () => {
    return filteredVisites.map(visite => {
      const filteredEtapes = etapes.filter(etape => etape.idVisite === visite.id);
      const etapeSujet = filteredEtapes.length > 0 ? filteredEtapes[0].sujet : '';
      const etapeDate = filteredEtapes.length > 0 ? new Date(filteredEtapes[0].date).toLocaleDateString() : '';

      return {
        'Nom Client': visite.nomClient,
        'Agence': visite.agence,
        'Nom': visite.nom,
        'Prénom': visite.prenom,
        'Fonction': visite.fonction,
        'Adresse': visite.adressClient,
        'Adresse 2': visite.adresse2,
        'Ville': visite.villeClient,
        'Code Postal': visite.cpClient,
        'Téléphone': visite.telClient,
        'Email': visite.emailClient,
        'Chiffre d’affaires': visite.caAnnuel,
        'Activité': visite.activite,
        'Appel 1': visite.appel1,
        'Appel 2': visite.appel2,
        'Appel 3': visite.appel3,
        'Appel 4': visite.appel4,
        'Date Visite': new Date(visite.dateVisite).toLocaleDateString(),
        'Commentaire': visite.commentaire,
        'Prochaine étape': etapeSujet,
        'Date prochaine étape': etapeDate,
        'Prochain RDV': new Date(visite.nextVisitDate).toLocaleDateString(),
        'Commercial': `${visite.firstname} ${visite.name}`
      };
    });
  };

  return (
    <div className='flex flex-row h-full w-full fixed'>
      <Sidebar />
      <div className="relative flex flex-col w-full h-full bg-bleuBlanc overflow-hidden">
        <div className="h-[15%] w-full">
          <Title title="Mon suivi prospection" handleExportExcel={getExcelData()} onSubmitFilter={handleFilter}/>
        </div>
        <div className='flex h-[85%] w-full mt-[2%] overflow-y-auto'>
            <div className='h-[6%]'>
              <table className='w-full h-full bg-marineLogo text-blanc'>
                <thead>
                  <tr>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Raison Sociale</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>
                        <div className='Flex flex-col'>
                          <div>Agence</div>
                          <div>
                            <select
                              value={selectedAgence}
                              onChange={(e) => setSelectedAgence(e.target.value)}
                              className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                            >
                              <option value="toutes">Toutes</option>
                              {agence.map((agenceItem) => (
                                <option key={agenceItem.code} value={agenceItem.code}>
                                  {agenceItem.nom}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Nom</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Prénom</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Fonction</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Adresse 1</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Adresse 2</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Ville</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Code Postal</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>N° de téléphone</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Email</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Chiffre d'affaires</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Activité</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>1er Appel</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>2eme Appel</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>3eme Appel</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>4eme Appel</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Date et heure RDV</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Commentaire</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Prochaine étape</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Date prochaine étape</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>Prochain RDV</th>
                      <th className='px-2 py-2 whitespace-nowrap w-40'>
                        <div className='Flex flex-col'>
                          <div>Commercial</div>
                          {(userInfo.user.role === "Administrateur" || userInfo.user.role === "Responsable") && (
                            <div>
                              <select
                                value={selectedCommercial}
                                onChange={(e) => setSelectedCommercial(e.target.value)}
                                className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                              >
                                <option value="tous">Tous</option> 
                                {commerciaux.map((commercial) => ( 
                                  <option key={commercial.idUser} value={commercial.idUser}>
                                    {commercial.name} {commercial.firstName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                      </th>
                  </tr>
                </thead>
              </table>
              
              <table className='w-full h-full table-fixed'>
                  <tbody>
                    <tr className='h-9'>
                      <td className='py-4 whitespace-nowrap text-center w-40 '>
                        <input type='text' list='raisonSociale' name='nomClient' onChange={handleInputChange} value={formData.nomClient} placeholder='Entreprise' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-full'/>
                        <datalist id='raisonSociale' className='absolute w-[200px]'>
                          { clients.map(c => (
                            
                            <option className='w-full' key={c.codeClient + ' / ' + c.codeAgence} value={c.codeClient + ' / ' + c.nomClient + ' / ' + c.codeAgence}>{c.codeClient} {c.nomClient} {c.codeAgence}</option>
                          ))}
                        </datalist>
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'>
                        <input type='text' name='site' value={formData.site} onChange={handleInputChange} placeholder='Agence' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                        
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'>
                        <input type='text' name='nom' list='nomContact' value={formData.nom} onChange={handleInputChange} placeholder='Nom' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                        <datalist id='nomContact' className='absolute w-[200px]'>
                          {contactClient && contactClient.length > 0 && 
                            contactClient.map(c => (
                              <option
                                className='w-full'
                                key={c.idContact}
                                value={`${c.nom} / ${c.prenom} / ${c.fonction}`}
                              >
                                {c.nom} {c.prenom} {c.fonction}
                              </option>
                            ))
                          }
                        </datalist>
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'>
                        <input type='text' name='prenom' value={formData.prenom} onChange={handleInputChange} placeholder='Prenom' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'>
                        <input type='text' name='fonction' value={formData.fonction} onChange={handleInputChange} placeholder='Fonction' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='adressEnt' value={formData.adressEnt} onChange={handleInputChange} placeholder='Adresse' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='adresse2' value={formData.adresse2} onChange={handleInputChange} placeholder='Adresse' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='villeEnt' value={formData.villeEnt} onChange={handleInputChange} placeholder='Ville' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='cpEnt' value={formData.cpEnt} onChange={handleInputChange} placeholder='Code Postale' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='numEnt' value={formData.numEnt} onChange={handleInputChange} placeholder='Téléphone' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='emailEnt' value={formData.emailEnt} onChange={handleInputChange} placeholder='Email' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='ca' value={formData.ca} onChange={handleInputChange} placeholder='CA' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='activite' value={formData.activite} onChange={handleInputChange} placeholder='Activité' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='date' name='appel1' value={formData.appel1} onChange={handleInputChange} className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='date' name='appel2' value={formData.appel2} onChange={handleInputChange} className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='date' name='appel3' value={formData.appel3} onChange={handleInputChange} className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='date' name='appel4' value={formData.appel4} onChange={handleInputChange} className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'>
                        <input
                          type='date'
                          name='visitDate'
                          value={visitDate || new Date().toISOString().split('T')[0]}
                          onChange={handleInputChange}
                          className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'
                        />
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'>
                        <button onClick={openCommentPopup} className='bg-vertLogo text-blanc rounded-md px-2 hover:bg-bleu hover:text-vertLogo'>Commentaire</button>
                      </td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='text' name='text' value={etap.text} onChange={handleInputChange} placeholder='Prochaine Etapes' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='date' name='date' value={etap.date} onChange={handleInputChange} className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='date' name='nextVisitDate' value={formData.nextVisitDate} onChange={handleInputChange} className='h-[24px] rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]' /></td>
                      <td className='py-4 whitespace-nowrap text-center w-40'><input type='button' onClick={handleSubmit} value='Ajouter' className=" mr-2 w-full bg-vertLogo flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo" /></td>
                    </tr>
                    {filteredVisites.map((visite, visiteI) => (
                      
                      
                      <tr key={visiteI} className='h-9 border border-x-0 border-b-1 text-center '>
                        {editingRow === visite.id ? (
                          <>
                          <td className='text-xs'>{editedData.nomClient}</td>
                          <td className='text-xs'><input type='text' name='agence' value={editedData.codeAgence} onChange={handleEditChange} /></td>

                          {(() => {
                            const associatedContacts = contact.filter((cont) => cont.idVisite === visite.id);

                            return associatedContacts.length > 0
                              ? associatedContacts.map((cont, index) => (
                                  <React.Fragment key={index}>
                                    
                                    <td className="text-xs">
                                      <input
                                        type="text"
                                        name={`nom_${cont.id}`}
                                        value={cont.nom || ""}
                                        onChange={(e) => handleEditChange(e, cont.id, 'nom')}
                                      />
                                    </td>
                                    <td className="text-xs">
                                      <input
                                        type="text"
                                        name={`prenom_${cont.id}`}
                                        value={cont.prenom || ""}
                                        onChange={(e) => handleEditChange(e, cont.id, 'prenom')}
                                      />
                                    </td>
                                    <td className="text-xs">
                                      <input
                                        type="text"
                                        name={`fonction_${cont.id}`}
                                        value={cont.fonction || ""}
                                        onChange={(e) => handleEditChange(e, cont.id, 'fonction')}
                                      />
                                    </td>
                                  </React.Fragment>
                                ))
                              : Array(3)
                                  .fill("")
                                  .map((_, index) => (
                                    <td key={index} className="text-xs">
                                      <input
                                        type="text"
                                        name={`empty_${index}`}
                                        value=""
                                        onChange={handleEditChange}
                                      />
                                    </td>
                                  ));
                          })()}
                          
                            <td className='text-xs'><input type='text' name='adressClient' value={editedData.adressClient} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='adresse2' value={editedData.adresse2} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='villeClient' value={editedData.villeClient} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='cpClient' value={editedData.cpClient} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='telClient' value={editedData.telClient} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='emailClient' value={editedData.emailClient} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='caAnnuel' value={editedData.caAnnuel} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='activite' value={editedData.activite} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='appel1' value={editedData.appel1} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='appel2' value={editedData.appel2} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='appel3' value={editedData.appel3} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='appel4' value={editedData.appel4} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='dateVisite' value={editedData.dateVisite} onChange={handleEditChange} /></td>
                            <td className='text-xs'><textarea name='commentaire' value={editedData.commentaire} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='text' name='prochaineEtape' value={editedData.prochaineEtape} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='dateProchaineEtape' value={editedData.dateProchaineEtape} onChange={handleEditChange} /></td>
                            <td className='text-xs'><input type='date' name='nextVisitDate' value={editedData.nextVisitDate} onChange={handleEditChange} /></td>
                            <td><button onClick={saveChanges} className='text-xs bg-vertLogo rounded-md px-2 text-white'>Save</button></td>
                            <td><button onClick={cancelEditing} className='text-xs bg-red-500 rounded-md px-2 text-white'>Cancel</button></td>
                          </>
                        ) : (
                          <>
                          <td className='text-xs'>{visite.nomClient}</td>
                          <td className='text-xs'>{visite.codeAgence}</td>

                          {(() => {
                            const associatedContacts = contact.filter((cont) => cont.idVisite === visite.id);
                            
                            return associatedContacts.length > 0
                              ? associatedContacts.map((cont, index) => (
                                  <React.Fragment key={index}>
                                    <td className="text-xs">{cont.nom}</td>
                                    <td className="text-xs">{cont.prenom}</td>
                                    <td className="text-xs">{cont.fonction}</td>
                                  </React.Fragment>
                                ))
                              : Array(3).fill("").map((_, index) => <td key={index} className="text-xs"></td>);
                          })()}


                            <td className='text-xs'>{visite.adressClient}</td>
                            <td className='text-xs'>{visite.adresse2}</td>
                            <td className='text-xs'>{visite.villeClient}</td>
                            <td className='text-xs'>{visite.cpClient}</td>
                            <td className='text-xs'>{truncateText(visite.telClient ? visite.telClient : '', 40)}</td>
                            <td className='text-xs'>{visite.emailClient}</td>
                            <td className='text-xs'>{visite.caAnnuel}</td>
                            <td className='text-xs'>{visite.activite}</td>
                            <td className='text-xs'>
                              {visite.appel1 ? (
                                  String(new Date(visite.appel1).getDate()).padStart(2, '0') === '01' &&
                                  String(new Date(visite.appel1).getMonth() + 1).padStart(2, '0') === '01' &&
                                  new Date(visite.appel1).getFullYear() === 1970 ? (
                                      ''
                                  ) : (
                                      `${String(new Date(visite.appel1).getDate()).padStart(2, '0')}/${String(new Date(visite.appel1).getMonth() + 1).padStart(2, '0')}/${new Date(visite.appel1).getFullYear()}`
                                  )
                              ) : (
                                  ''
                              )}
                            </td>
                            <td className='text-xs'>
                              {visite.appel2 ? (
                                  String(new Date(visite.appel2).getDate()).padStart(2, '0') === '01' &&
                                  String(new Date(visite.appel2).getMonth() + 1).padStart(2, '0') === '01' &&
                                  new Date(visite.appel2).getFullYear() === 1970 ? (
                                      ''
                                  ) : (
                                      `${String(new Date(visite.appel2).getDate()).padStart(2, '0')}/${String(new Date(visite.appel2).getMonth() + 1).padStart(2, '0')}/${new Date(visite.appel2).getFullYear()}`
                                  )
                              ) : (
                                  ''
                              )}
                            </td>
                            <td className='text-xs'>
                              {visite.appel3 ? (
                                  String(new Date(visite.appel3).getDate()).padStart(2, '0') === '01' &&
                                  String(new Date(visite.appel3).getMonth() + 1).padStart(2, '0') === '01' &&
                                  new Date(visite.appel3).getFullYear() === 1970 ? (
                                      ''
                                  ) : (
                                      `${String(new Date(visite.appel3).getDate()).padStart(2, '0')}/${String(new Date(visite.appel3).getMonth() + 1).padStart(2, '0')}/${new Date(visite.appel3).getFullYear()}`
                                  )
                              ) : (
                                  ''
                              )}
                            </td>
                            <td className='text-xs'>
                              {visite.appel4 ? (
                                  String(new Date(visite.appel4).getDate()).padStart(2, '0') === '01' &&
                                  String(new Date(visite.appel4).getMonth() + 1).padStart(2, '0') === '01' &&
                                  new Date(visite.appel4).getFullYear() === 1970 ? (
                                      ''
                                  ) : (
                                      `${String(new Date(visite.appel4).getDate()).padStart(2, '0')}/${String(new Date(visite.appel4).getMonth() + 1).padStart(2, '0')}/${new Date(visite.appel4).getFullYear()}`
                                  )
                              ) : (
                                  ''
                              )}
                            </td>
                            <td className='text-xs'>
                              {visite.dateVisite ? (
                                  String(new Date(visite.dateVisite).getDate()).padStart(2, '0') === '01' &&
                                  String(new Date(visite.dateVisite).getMonth() + 1).padStart(2, '0') === '01' &&
                                  new Date(visite.dateVisite).getFullYear() === 1970 ? (
                                      ''
                                  ) : (
                                      `${String(new Date(visite.dateVisite).getDate()).padStart(2, '0')}/${String(new Date(visite.dateVisite).getMonth() + 1).padStart(2, '0')}/${new Date(visite.dateVisite).getFullYear()}`
                                  )
                              ) : (
                                  ''
                              )}
                            </td>
                            <td className='text-xs max-h-[60px] h-[59px] flex items-center justify-center'><textarea readonly="true" className='w-full min-h-[50px] focus:outline-none cursor-default'>{visite.commentaire}</textarea></td>
                            {(() => {
                              const filteredEtapes = etapes.filter(etape => etape.idVisite === visite.id);
                              if (filteredEtapes.length > 0) {
                                return (
                                  <>
                                    <td className='text-xs'>{filteredEtapes[0].sujet}</td>
                                    <td className='text-xs'>
                                      {filteredEtapes[0].date ? (
                                          String(new Date(filteredEtapes[0].date).getDate()).padStart(2, '0') === '01' &&
                                          String(new Date(filteredEtapes[0].date).getMonth() + 1).padStart(2, '0') === '01' &&
                                          new Date(filteredEtapes[0].date).getFullYear() === 1970 ? (
                                              ''
                                          ) : (
                                              `${String(new Date(filteredEtapes[0].date).getDate()).padStart(2, '0')}/${String(new Date(filteredEtapes[0].date).getMonth() + 1).padStart(2, '0')}/${new Date(filteredEtapes[0].date).getFullYear()}`
                                          )
                                      ) : (
                                          ''
                                      )}
                                    </td>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <td className='text-xs'></td>
                                    <td className='text-xs'></td>
                                  </>
                                );
                              }
                            })()}
                            <td className='text-xs'>
                              {visite.nextVisitDate ? (
                                  String(new Date(visite.nextVisitDate).getDate()).padStart(2, '0') === '01' &&
                                  String(new Date(visite.nextVisitDate).getMonth() + 1).padStart(2, '0') === '01' &&
                                  new Date(visite.nextVisitDate).getFullYear() === 1970 ? (
                                      ''
                                  ) : (
                                      `${String(new Date(visite.nextVisitDate).getDate()).padStart(2, '0')}/${String(new Date(visite.nextVisitDate).getMonth() + 1).padStart(2, '0')}/${new Date(visite.nextVisitDate).getFullYear()}`
                                  )
                              ) : (
                                  ''
                              )}
                            </td>
                            <td className='text-xs'>{visite.firstname} {visite.name}</td>
                            <td><button onClick={() => startEditing(visite)} className='text-xs bg-bleu rounded-md px-2 text-white'>Edit</button></td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
        </div>
      </div>
      {showCommentPopup && (
        <div className="fixed inset-0 bg-noir bg-opacity-50 flex items-center justify-center">
          <div className="bg-blanc rounded-lg p-6 w-[40%] border border-bleauLogo">
            <h2 className="text-lg font-bold mb-4">Ajouter un commentaire</h2>
            <textarea
              value={commentaireTemp}
              onChange={(e) => setCommentaireTemp(e.target.value)}
              rows="10"
              className="w-full p-2 border rounded-md"
            />
            <div className="flex justify-end mt-4">
              <button onClick={closeCommentPopup} className="px-4 py-2 bg-red-500 text-white rounded-md mr-2">Annuler</button>
              <button onClick={saveCommentaire} className="px-4 py-2 bg-vertLogo text-white rounded-md">Enregistrer</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import Top from '../Containers/Top';
import GraphCAM from '../Containers/graphCAM';
import Chats from '../Containers/Chats';
import Etape from '../Containers/Etapes';
import VisitePro from '../Containers/VisitePro';
import Chargement from '../Containers/chargement';
import Dexie from 'dexie';
import db from '../../Db/db';
import pLimit from 'p-limit';

function Accueil({ url, urlTrimble }) {

    const today = new Date();
    today.setFullYear(today.getFullYear() - 1, 0, 1);
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const limit = pLimit(3);

    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    const checkDBExistence = async () => {

        const dbExist = await Dexie.exists('Crm');

        return dbExist;
    };

    const isTableEmpty = async (tableName) => {
        const count = await db[tableName].count();
        return count === 0;
    };

    useEffect(() => {
        const loadDataIfNeeded = async () => {
            console.log("loading data...");
            setLoading(true); 
            
            const lastLoginDate = new Date(userInfo.user.last_login);
            const currentDate = new Date()
            const differenceInMilliseconds = (currentDate.getTime()) - lastLoginDate.getTime();
            const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
            const dbExist = await checkDBExistence();
            if (lastLoginDate && differenceInMilliseconds < sevenDaysInMilliseconds) {
                console.log("ok")
                if (!dbExist) {
                    console.log("ok1")
                    db.open()
                    await loadDataFromServer();
                } else {
                    console.log("ok2")
                    const agencesEmpty = await isTableEmpty('agences');
                    if (agencesEmpty) {
                        console.log("ok3")
                        await loadDataFromServer();
                    } else {
                        console.log("ok4")
                    }
                }
            } else {
                console.log("ok5")
                if (dbExist) {
                    console.log("ok6")
                    await db.delete();
                    await db.open()
                    await loadDataFromServer();
                } else {
                    console.log("ok7")
                    db.open()
                    await loadDataFromServer();
                }
            }
            setLoading(false); 

        };
        
        loadDataIfNeeded();
    }, []); 

    const headers = { 
        "Content-type": "Application/json", 
        "Authorization": `Bearer ${userInfo.token}` 
    };
    

    const loadDataFromServer = async () => {
        
        try {
            console.log('Fetching data from server...');
            const fetchPromises = [
                () => axios.get(url + 'client/all/',  {headers: headers} ), //ok
                () => axios.get(url + 'agences/', {headers: headers} ), //ok
                () => axios.get(url + 'activite/',  {headers: headers} ), //ok


                () => axios.get(url + 'team/',  {headers: headers} ), //ok
                () => axios.get(url + 'client/etapes/',  {headers: headers} ), //ok
                () => axios.get(url + 'client/com/',  {headers: headers} ), //ok
                () => axios.get(url + 'visit/',  {headers: headers} ), //ok

                () => axios.get(url + 'client/cityCharg/',  {headers: headers} ), //ok
                () => axios.get(url + 'client/cityLivr/',  {headers: headers} ), //ok
                () => axios.get(url + 'client/transporteur/',  {headers: headers} ), //ok
                () => axios.get(url + 'client/transporteurByCom/',  {headers: headers} ), //ok
                () => axios.get(url + 'client/contact/', {headers: headers} ), //ok

                () => axios.get(url + 'client/allAct/',  {headers: headers} ), //ok

                () => axios.get(url + 'client/allDep/',  {headers: headers} ), //ok
                () => axios.get(urlTrimble + 'emission/energies/', { headers: { "Content-type": "Application/json" } }),
                () => axios.get(urlTrimble + 'emission/conso/', { headers: { "Content-type": "Application/json" } }),

                () => axios.get(url + 'client/matos/',  {headers: headers} ), //ok
            ];

            const responses = await Promise.all(fetchPromises.map(fetchFn => limit(fetchFn)));


            const [clientsRes, agencesRes, activiteRes, teamRes, etapesRes, topRes, visitesRes, cityCharg, cityLivr, tansporteurRes, transporteurComRes, contactsRes, actRes, allDepRes, energiesRes, consoRes, matosRes] = responses;
           

            await db.transaction('rw', db.clients, db.agences, async () => {
                
                await db.clients.bulkPut(clientsRes.data);
                await db.agences.bulkPut(agencesRes.data);
            });

            await db.transaction('rw', db.activite, db.team, db.etapes, async () => {
                
                await db.activite.bulkPut(activiteRes.data);
                await db.team.bulkPut(teamRes.data);
                await db.etapes.bulkPut(etapesRes.data);
            });

            await db.transaction('rw', db.etapes, db.top, db.ca, db.caYear,  async () => {
                await db.top.bulkPut(topRes.data.result);
            
                const donneesCA = []; 
                const donneeYear = []

                topRes.data.result.forEach(data => {
                    const cleClient = `${data.codeClient}-${data.nomClient}-${data.codeAgence}`;
                    
                    let clientIndex = donneesCA.findIndex(item => item.cleClient === cleClient);
                    if (clientIndex === -1) {
                        donneesCA.push({
                            cleClient: cleClient,
                            codeClient: data.codeClient,
                            nomClient: data.nomClient,
                            codeAgence: data.codeAgence,
                            commercial: data.commercial,
                            mois: []
                        });
                        donneeYear.push({
                            cleClient: cleClient,
                            codeClient: data.codeClient,
                            nomClient: data.nomClient,
                            codeAgence: data.codeAgence,
                            commercial: data.commercial,
                            annees: {}
                        })
                        clientIndex = donneesCA.length - 1;
                    }
                   
                    const mois = data.mois;
                    const annee = data.annee;

                    if (!donneeYear[clientIndex].annees[annee]) {
                        donneeYear[clientIndex].annees[annee] = {};
                        for (let i = 1; i <= 12; i++) {
                            donneeYear[clientIndex].annees[annee][i] = {
                                ca: 0,
                                ot: 0
                            };
                        }
                    }
                
                    donneeYear[clientIndex].annees[annee][mois].ca = parseFloat(data.CA);
                    donneeYear[clientIndex].annees[annee][mois].ot = parseFloat(data.OT);
                
                    const currentYearCA = annee === new Date().getFullYear() ? parseFloat(data.CA) : 0;
                    const prevYearCA = annee === new Date().getFullYear() - 1 ? parseFloat(data.CA) : 0;
                    const currentYearOT = annee === new Date().getFullYear() ? parseFloat(data.OT) : 0;
                    const prevYearOT = annee === new Date().getFullYear() - 1 ? parseFloat(data.OT) : 0;

                    
                
                    for (let i = 1; i <= 12; i++) {
                        const moisIndex = i - 1;
                        const clientMois = donneesCA[clientIndex].mois;
                
                        if (!clientMois[moisIndex]) {
                            clientMois[moisIndex] = {
                                mois: i,
                                ca: 0,
                                ca_n_1: 0,
                                ot: 0,
                                ot_n_1: 0
                            };
                        }
                
                        if (mois === i && currentYearCA !== 0) {
                            clientMois[moisIndex].ca = currentYearCA;
                            clientMois[moisIndex].ot = currentYearOT;
                        }
                        if (mois === i && prevYearCA !== 0) {
                            clientMois[moisIndex].ca_n_1 = clientMois[moisIndex].ca_n_1 || prevYearCA;
                            clientMois[moisIndex].ot_n_1 = clientMois[moisIndex].ot_n_1 || prevYearOT;
                        }
                    }
                });
                
                
                
                await db.ca.bulkPut(donneesCA);
                await db.caYear.bulkPut(donneeYear);
            
            });

            await db.transaction('rw', db.visites, db.visitePrevoir, db.visitePrevue, async () => {
                await db.visites.bulkPut(visitesRes.data.visites);
                await db.visitePrevoir.bulkPut(visitesRes.data.visitePrevoir);
                await db.visitePrevue.bulkPut(visitesRes.data.visitePrevue);
            });

            await db.transaction('rw', db.cityCharg, db.cityLivr, async () => {
                await db.cityCharg.bulkPut(cityCharg.data);
                await db.cityLivr.bulkPut(cityLivr.data);
            });

            await db.transaction('rw', db.tansporteur, db.transporteurCom, async () => {
                await db.tansporteur.bulkPut(tansporteurRes.data);
                await db.transporteurCom.bulkPut(transporteurComRes.data);
            });

            await db.transaction('rw', db.contacts, db.act, db.depart, async () => {
                await db.contacts.bulkPut(contactsRes.data);
                await db.act.bulkPut(actRes.data);
                await db.depart.bulkPut(allDepRes.data);
            });

            await db.transaction('rw', db.energies, db.conso, async () => {
                await db.energies.bulkPut(energiesRes.data);
                await db.conso.bulkPut(consoRes.data);
            });

            await db.transaction('rw', db.matos, async () => {
                await db.matos.bulkPut(matosRes.data);
            });
            //});
        } catch (error) {
            console.error(error);
        }

        try {
            const energies = await db.energies.toArray();
            const ademeFactors = await Promise.all(energies.map(async (adem) => {
                try {
                    const facteursRes = await axios.get(`https://data.ademe.fr/data-fair/api/v1/datasets/base-carboner/lines?qs=Type_Ligne:Elément AND Identifiant_de_l'élément:${adem.adem}`, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-type": "Application/json"
                        }
                    });
        
                    const facteurs = facteursRes.data.results[0];
                    return {
                        NomF: facteurs.Nom_base_français,
                        DatC: facteurs.Date_de_création,
                        PerV: facteurs.Période_de_validité,
                        Unit: facteurs.Unité_français,
                        Valu: facteurs.Total_poste_non_décomposé,
                        ademId: adem.adem 
                    };
                } catch (error) {
                    console.error(`Error fetching Ademe data for adem ${adem.adem}:`, error);
                    return null;
                }
            }));
        
            const filteredAdemeFactors = ademeFactors.filter(factor => factor !== null);
        
            await db.transaction('rw', db.adem, async () => {
                await db.adem.bulkPut(filteredAdemeFactors);
            });
        
            console.log('Successfully saved Ademe factors to IndexedDB.');
        } catch (error) {
            console.error('Error processing Ademe factors:', error);
        }
    };
    


    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    {<Title title="Accueil" btn={true} />}
                </div>
                <div className="mt-2 h-[85%] w-full">
                    <div className='w-full h-full flex items-center'>

                    {loading ? (
                    <Chargement />
                    ) :  (
<>
                        <div className='h-full w-[58%] m-[4px] flex flex-col max-lg:hidden'>
                            <div className='w-full h-[50%] flex flex-col m-4'>

                                <Top block={true} />

                            </div>
                            <div className='w-full h-[50%] flex items-center justify-center m-4'>
                                {<GraphCAM />}
                            </div>
                        </div>
                        
                        <div className='h-full w-[40%] flex flex-col items-center '>
                            <div className='h-[32%] w-[80%]  '>
                                {<Etape />}
                                
                            </div>
                            <div className='h-[32%] w-[80%]  '>
                                {<VisitePro />}
                                
                            </div>

                            <div className='h-[30%] w-[80%]'>
                                
                                <Chats url={url} id={id} token={userInfo.token}/>
                                    
                            </div>
                        </div>
                        </>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Accueil;




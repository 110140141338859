import React, { useState, useEffect } from 'react';
import axios from "axios";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import db from '../../Db/db';
import FormMergeClient from "../Containers/formulaire/FormMergeClient";

function Conso({ url }) {
    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);

    const [consoData, setConsoData] = useState([]);
    const [client, setClient] = useState([]);
    const [agence, setAgence] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState("");
    const [selectedClients, setSelectedClients] = useState([]);
    const [parentName, setParentName] = useState("");
    const [commerciaux, setCommerciaux] = useState([]);
    const [associerDissocier, setAssocierDissocier] = useState("aucun");

    useEffect(() => {
        async function fetchData() {
            const data = await db.clients.toArray();
            setClient(data.filter(d => d.typeClient === 'client-effectif'));
            const data2 = await db.agences.toArray();
            setAgence(data2);
            const data3 = await db.team.toArray();
            setCommerciaux(data3)
        }
        fetchData();
        conso();
    }, [url]);

    useEffect(() => {
        handleFilter();
    }, [selectedAgence]);

    const handleFilter = () => {
        db.clients.toArray().then((data) => {
            let filteredData = []

            if (selectedAgence) {
                filteredData = data.filter((client) => client.codeAgence === selectedAgence);
            }
    
            setClient(filteredData);
        });
    };

    function conso() {
        axios.get(url + 'client/allParents/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {       
            setConsoData(res.data.data);
        })
        .catch(error => {
            console.error(error);
        });
    }

    function handleSelectClient(client) {
        setSelectedClients(prev => {
            const exists = prev.some(c => c.child === client.child && c.agency === client.agency);
    
            return exists
                ? prev.filter(c => !(c.child === client.child && c.agency === client.agency))
                : [...prev, client];
        });
    }
    

    function validateConsolidation() {
        
        if (!parentName.trim() || selectedClients.length === 0) {
            alert("Veuillez saisir un nom pour le client parent et sélectionner des clients !");
            return;
        }

        const commercial = associerDissocier;
        const codeClient = parentName+'_PARENT'
        axios.post(url+'client/', {
            codeClient: codeClient,
            codeAgence: 'HRX',
            nomClient: parentName,
            type: 'parent',
            commercial: commercial
        },{
        headers: { 
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            'Authorization': 'Bearer ' + userInfo.token
      }
      }).catch(function (error) {
        console.log(error);
      });

        axios.post(url+'client/merge', {
            clientParent: codeClient,
            clientEnfant: selectedClients
            
        },{
            headers: { 
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        }).then(response => {
            alert('Les clients ont bien été consolidés!'+ response);
        }).catch(function (error) {
            alert('Une erreur s\'est produite lors de la création du parent.');
            console.log(error);
        });
    }

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Consolidation Clients" btn={false} />
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[60%] h-full font-bahn'>
                        
                        <div className='h-[4%] w-full'>
                            <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[100%] border-collapse ">
                                <thead className='h-[6%] max-h-1.5'>
                                    <tr className='bg-marineLogo text-blanc'>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Clients</th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                                            Agence :
                                            <select
                                                value={selectedAgence}
                                                onChange={(e) => setSelectedAgence(e.target.value)}
                                                className="ml-2 text-xs bg-marineLogo text-blanc border-none"
                                            >
                                                <option value="">Aucune</option>
                                                {agence.map((agenceItem) => (
                                                    <option key={agenceItem.code} value={agenceItem.code}>
                                                    {agenceItem.nom}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Consolider</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className='h-[60%] w-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>                
                            <table className="table-fixed h-[96%] border-collapse items-center bg-transparent w-[100%]">
                                <tbody className='overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[94%] min-h-[50%]'>
                                    {client.map((cl, index) => (
                                        <tr key={index}>
                                            <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-marineLogo">{cl.nomClient} ({cl.codeClient})</td>
                                            <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-center">{cl.codeAgence}</td>
                                            <td className='group  text-center'>
                                                <input 
                                                    type="checkbox" 
                                                    onChange={() => handleSelectClient({ child: cl.codeClient, name: cl.nomClient, agency: cl.codeAgence })} 
                                                    checked={selectedClients.some(c => c.child === cl.codeClient && c.agency === cl.codeAgence)}
                                                    className="w-6 h-6 cursor-pointer accent-[#9EBF43]"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-4 p-3 bg-gray-100 rounded-md h-[32%]">
                            <div className='flex'>
                                <div className="flex-1">
                                    <label className="block text-sm font-semibold text-marineLogo">Nom du parent :</label>
                                    <input 
                                        type="text" 
                                        value={parentName} 
                                        onChange={(e) => setParentName(e.target.value)}
                                        className="mt-1 p-2 w-full border border-marineLogo rounded-md"
                                        placeholder="Ex: Client Consolidé A"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label className="block text-sm font-semibold text-marineLogo">Commercial :</label>
                                    <select
                                        value={associerDissocier}
                                        onChange={(e) => setAssocierDissocier(e.target.value)}
                                        className="mt-1 ml-2 p-2 bg-blanc w-full  text-marineLogo border border-marineLogo rounded-md"
                                    >
                                        <option value="aucun">Aucun</option>
                                        <option value="">Tous</option> 
                                        {commerciaux.map((commercial) => ( 
                                            <option key={commercial.idUser} value={commercial.idUser}>
                                                {commercial.name} {commercial.firstName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                
                            </div>
                            <label className="block text-sm font-semibold text-marineLogo mt-2">Liste des enfants :</label>
                            <div className="overflow-y-auto max-h-32 scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                                <ul className="pl-5 list-disc text-marineLogo">
                                    {selectedClients.map(cl => (
                                        <li key={`${cl.child}-${cl.agency}`} className="py-1">
                                            <span className="text-sm text-marineLogo">{cl.name}</span> 
                                            <span className="text-sm text-marineLogo"> ({cl.child}) - {cl.agency}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <button 
                                onClick={validateConsolidation} 
                                className="mt-2 w-full bg-vertLogo text-blanc py-2 rounded-md font-semibold hover:bg-blanc hover:border hover:border-vertLogo hover:text-vertLogo"
                            >
                                Valider la Consolidation
                            </button>
                        </div>
                    </div>
                    <div className='w-[40%] h-full flex justify-center overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[94%] min-h-[50%]'>
                        <div className="w-full p-4 rounded-lg shadow-lg bg-white">
                            <h2 className="text-lg font-bold text-marineLogo mb-3 border-b border-marineLogo">Clients Consolidés</h2>
                            <ul className="space-y-3">
                                {consoData.map(conso => (
                                    <li key={conso.parent} className="border-b pb-2">
                                        <strong className="text-marineLogo">{conso.parent}</strong>
                                        <ul className="pl-5 mt-2 list-disc text-gray-700">
                                            {conso.children.map(child => (
                                                <li key={`${child.child}-${child.agency}`} className="py-1">
                                                    <span className="font-medium">{child.name}</span> 
                                                    <span className="text-sm text-gray-500"> ({child.child}) - {child.agency}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Conso;
